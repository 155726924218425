
<template>
  <comprehensive>
    <div v-loading="pageLoading">
      <div class="supplier_top"></div>
      <div class="supplier_div">
        <div style="color: #333333; font-size: 16px">最新入驻供应商</div>
        <div
          class="supplierDiv"
          v-for="(item, index) in supplierList"
          :key="index"
        >
          {{ item.EnterpName }}
        </div>
      </div>
      <div class="supplier_bottom">
        <div class="stepImg">
          <div class="stepImg_top">入驻流程</div>
          <div class="stepImg_bottom">
            <div style="display: flex">
              <div class="stepImg_yuan">1</div>
              申请入驻
            </div>
            <div class="stepImg_xian"></div>
            <div style="display: flex">
              <div class="stepImg_yuan">2</div>
              填写资料
            </div>
            <div class="stepImg_xian"></div>
            <div style="display: flex">
              <div class="stepImg_yuan">3</div>
              平台审核
            </div>
            <div class="stepImg_xian"></div>
            <div style="display: flex">
              <div class="stepImg_yuan">4</div>
              入驻成功
            </div>
          </div>
        </div>
        <div class="stepDiv">
          <div class="stepDivText" style="padding-left: 493px" v-if="isLogin">
            <img src="./img/stepImgTag1.png" style="width: 24px; height: 20px; margin-right: 11px"/>
            您已注册成为平台会员
          </div>
          <div class="stepDivText" style="padding-left: 457px" v-if="!isLogin">
            <img src="./img/stepImgTag3.png" style="width: 24px; height: 24px; margin-right: 11px"/>请登录查看您是否符合入驻资格
          </div>

          <div class="stepDivText" style="margin: 20px 0px; padding-left: 493px" v-if="isLogin && !isCompany">
            <img src="./img/stepImgTag2.png" style="width: 24px; height: 24px; margin-right: 11px"/>您尚未通过企业实名认证，点此
            <span @click="JumpCenter(7)" style="color: rgba(239, 33, 71, 1); padding-left: 10px; cursor: pointer;">认证</span>
          </div>

          <div class="stepDivText" style="margin: 20px 0px; padding-left: 493px" v-if="isLogin && isCompany">
            <img src="./img/stepImgTag4.png" style="width: 24px; height: 24px; margin-right: 11px"/>
            您已通过企业实名认证
          </div>
          <div @click="onGoing" v-if="isLogin && isCompany && !residentStatus" class="stepDivBtn step2" style=" cursor: pointer; background-color: #ef2147; color: #ffffff;margin-top: 24px; ">
            立即入驻
          </div>
          <div v-if="isLogin && !isCompany" class="stepDivBtn step2" style="cursor: pointer; background-color: #d5d5d5; color: #999999;margin-top: 24px;">
            立即入驻
          </div>
          <div @click="onGoing" v-if="residentStatus && residentStatus == '1'" class="stepDivBtn step2" style="cursor: pointer; background-color: #d5d5d5; color: #999999;margin-top: 24px;">
            已入驻
          </div>
          <div @click="onGoing" v-if="residentStatus && residentStatus == '2'" class="stepDivBtn step2" style="cursor: pointer; background-color: #d5d5d5; color: #999999;margin-top: 24px;">
            已驳回
          </div>
          <div @click="onGoing" v-if="residentStatus && residentStatus == '0'" class="stepDivBtn step2" style="cursor: pointer; background-color: #d5d5d5; color: #999999;margin-top: 24px;">
            审核中
          </div>
          <div @click="login" v-if="!isLogin" class="stepDivBtn step2" style=" cursor: pointer; background-color: #ef2147; color: #ffffff; margin-top: 24px; ">
            立即登录
          </div>
        </div>
      </div>
    </div>
  </comprehensive>
</template>

<script>
import util from "@/libs/util.js";
import comprehensive from "../../components/comprehensive.vue";
import { mapState, mapActions } from "vuex";
import storage from "@/utils/storage";
export default {
  name: "bid",
  components: {
    comprehensive,
  },
  data() {
    return {
      userInfo:null,
      supplierList: [],
      isLogin: false,
      isCompany: false,
      residentStatus:'',
    };
  },
  created() {
    if(storage.getStorage("userInfo")){
        this.userInfo = storage.getStorage("userInfo");
    }
    this.getNewSupplierList().then((res) => {
        this.supplierList = res.resultdata;
    });
    if (this.userInfo) {
        this.isLogin = true;
        if (this.userInfo.company_id != "" && this.userInfo.company_id != null && this.userInfo.company_id != undefined && this.userInfo.company_id != 0) {
            this.isCompany = true;
            this.getResidentStatus(this.userInfo.company_id).then((obj) => {
                this.residentStatus = obj.resultdata.Status ?  obj.resultdata.Status : '';
            });
        } else {
          this.isCompany = false;
        }
    } else {
      this.isLogin = false;
    }
  },
  methods: {
    ...mapActions("ddmg/bid", ["getNewSupplierList","getResidentStatus","getAuthority"]),
    onGoing() {
        this.getAuthority(this.userInfo).then((res) => {
            if(res.msgcode == 1 && res.message == "成功!"){
                this.$router.push({ name: "supplierApply" });
            }else{
                this.$message(res.message);
            }
        });
    },
    login() {
        this.isToken();
    },
  },
  mounted() {},
  computed: {
    ...mapState("ddmg/bid", {
      pageLoading: (state) => state.pageLoading,
    }),

  },

};
</script>
<style lang="scss" scoped>
.supplier_bottom {
  width: 100%;
  height: 622px;
  background: url(./img/stepImg2.png) no-repeat center;
  background-size: 100% 100%;
  .stepDiv {
    width: 1200px;
    height: 228px;
    background-color: #2f3c4b;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .stepDivText {
      font-weight: 500;
      color: #ffffff;
      font-size: 18px;
      padding-left: 434px;
      display: flex;
      align-items: center;
    }
    .stepDivBtn {
      width: 120px;
      height: 42px;
      line-height: 40px;
      background-color: #d5d5d5;
      color: #999999;
      font-size: 20px;
      text-align: center;
      margin-left: 540px;
    }
  }
  .stepImg_bottom {
    margin-top: 86px;
    text-align: center;
    font-weight: 500;
    color: #333333;
    font-size: 32px;
    display: flex;
    align-items: center;
    .stepImg_yuan {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      background-color: #212b37;
      font-weight: 500;
      color: #ffffff;
      font-size: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
    }
    .stepImg_xian {
      width: 65px;
      height: 0px;
      border: 1px solid #212b37;
      margin: 0px 36px;
    }
  }
  .stepImg {
    width: 1200px;
    height: 310px;
    margin: 0 auto;
    .stepImg_top {
      font-weight: 500;
      color: #333333;
      font-size: 52px;
      padding-top: 60px;
      text-align: center;
    }
  }
}
.supplier_top {
  width: 100%;
  height: 507px;
  background: url(./img/stepImg1.png) no-repeat center;
  background-size: 100% 100%;
}
.supplier_div {
  width: 1040px;
  height: 198px;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 8%);
  margin: 0 auto;
  position: relative;
  top: -90px;
  padding: 0px 80px;
  display: flex;
  align-items: center;
  .supplierDiv {
    width: 120px;
    height: 21px;
    background-color: #ffece4;
    border-radius: 12px;
    padding: 11px 31px;
    font-weight: 500;
    color: #ef2147;
    font-size: 16px;
    margin-left: 48px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
}
</style>

